<template>
  <div class="topic-homework-page">
    <v-row justify="center">
      <v-col md="6">
        <div class="mx-4">
          <div v-if="!homework || isLoading" class="mt-2">
            <v-skeleton-loader
              type="list-item-avatar, list-item-three-line"
            ></v-skeleton-loader>
          </div>
          <div v-else>
            <v-list-item class="mx-0 px-0">
              <v-list-item-content>
                <v-list-item-subtitle class="my-1"
                  >By {{ homework.assignee.full_name }}</v-list-item-subtitle
                >
                <v-list-item-subtitle class="primary--text mt-1"
                  ><strong>{{ homework.subject.name }}</strong> -
                  {{ homework.subject.room ? homework.subject.room.sections : "Extra Subject" }}</v-list-item-subtitle
                >

                <v-list-item-subtitle class="my-1 caption">
                  Assigned on {{ moment(homework.created).format("Do MMM") }}
                </v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <!-- homework description -->
            <div class="my-2" v-if="homework">
              <p
                class="content-description mb-0"
                v-html="TextHelper.linkify(Helper.markuptext(homework.description, '*', 'strong'))"
              ></p>
            </div>
            <v-divider v-if="homework && homework.homework_documents.length" />
            <!-- homework documents -->
            <v-list
              class="my-0 py-0"
              v-if="homework && homework.homework_documents.length"
            >
              <v-list-item
                class="px-0"
                color="red"
                v-for="homeworkDocument in homework.homework_documents"
                :key="homeworkDocument.id"
              >
                <v-list-item-avatar>
                  <v-icon>mdi-file</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="py-0">
                  <v-list-item-subtitle
                    class="font-weight-bold"
                    v-if="homeworkDocument.file_name.split('.').length > 1"
                  >
                    {{
                      homeworkDocument.file_name
                        .split(".")
                        .slice(-1)[0]
                        .toUpperCase()
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="subtitle-2 text-wrap">
                    {{ homeworkDocument.file_name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon class="align-center">
                  <v-btn
                    outlined
                    color="primary"
                    class="non-focused"
                    v-if="homeworkDocument.progress == 100"
                    @click="openFile(homeworkDocument)"
                    ><v-icon>mdi-check</v-icon> Open</v-btn
                  >
                  <v-btn
                    @click="downloadDocument(homeworkDocument)"
                    color="primary"
                    class="non-focused"
                    icon
                    v-else
                  >
                    <v-icon v-if="homeworkDocument.progress == null"
                      >mdi-download</v-icon
                    >
                    <span v-else>{{ homeworkDocument.progress }}%</span>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <v-chip
              small
              :color="
                moment().isAfter(moment(homework.deadline)) ? 'red' : 'primary'
              "
            >
              <v-icon left> mdi-clock </v-icon>
              Deadline: {{ moment(homework.deadline).format("ll") }}</v-chip
            >
          </div>
        </div>
      </v-col>
    </v-row>

<!-- Copy to other Classroom dialog -->
        <v-dialog
            v-model="copyToOtherClassroomDialog.visible"
            width="500"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                <v-row>
                  <v-col cols="10">
                    <p class="mb-1" style="word-break: break-word">Copy to other classroom</p>
                  </v-col>
                </v-row>
               <v-btn icon absolute top right @click="copyToOtherClassroomDialog.visible=false; copyToOtherClassroomDialog.classroom=null; copyToOtherClassroomDialog.subject=null">
                 <v-icon>mdi-close</v-icon>
               </v-btn>
              </v-card-title>

              <v-card-text>
                <v-select
                  :items="allRooms"
                  color="primary"
                  v-model="copyToOtherClassroomDialog.classroom"
                  dense 
                  outlined
                  class="mt-3"
                  label="Select Classroom"
                  hide-details
                  item-value="id"
                  item-text="sections"
                  clearable
                ></v-select>

                <v-select
                  dense
                  outlined
                  class="mt-3"
                  label="Select Subject"
                  :items="roomSubjects.filter(subject => subject.id != homework.subject.id)"
                  v-model="copyToOtherClassroomDialog.classroomSubject"
                  item-text="name"
                  item-value="id"
                  :disabled="!copyToOtherClassroomDialog.classroom || isLoading"
                  clearable
                ></v-select>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="mt-n7 mb-3"
                  color="primary"
                  block
                  :disabled="!(copyToOtherClassroomDialog.classroom && copyToOtherClassroomDialog.classroomSubject)"
                  @click="copyHomework"
                >
                  Copy 
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> 

    <!-- delete dialog -->
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this homework?"
      description="This action cannot be undone"
      @successCallback="deleteHomework"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
    <!-- create edit sheet -->
    <!-- intract create conference sheet -->
    <intract-create-edit-sheet
      v-if="canManage"
      key="homework-create-sheet"
      ref="homework-create-sheet"
      title="Edit Homework"
      description="Assign homework to students along with a deadline!"
      persistent
      enable-media
      :editId="editHomeworkSheet.editId"
      :visible="editHomeworkSheet.visible"
      @close="editHomeworkSheet.visible = false"
      :fields="formFields"
      :endpoint="endpoints.homeworkViewSet"
      :data-object="editHomeworkSheet.obj"
      edit-success-message="Homework edited successfully!"
      :getObject="finalOperationsBeforeHomeworkEdited"
      @objectCreated="homeworkEdited"
      @updateObject="(obj) => (editHomeworkSheet.obj = obj)"
    >
    </intract-create-edit-sheet>
    <!-- submit homework sheet (student) -->
    <intract-create-edit-sheet
      v-if="currentUser.is_student"
      class="homework-submission-create-edit-sheet"
      title="Submit Homework"
      description="Write a description about your submission and attach necessary files"
      :visible="submissionSheet.visible"
      @close="submissionSheet.visible = false"
      :fields="submissionFormFields"
      :endpoint="endpoints.homeworkSubmissionViewSet"
      :edit-id="submissionSheet.editId"
      :data-object="submissionSheet.obj"
      :create-success-message="submissionSheet.createSuccessMessage"
      enable-media
      @objectCreated="
        (obj) =>
          $router.push({
            name: 'HomeworkSubmission',
            params: { submissionId: obj.id },
          })
      "
      @updateObject="(obj) => (submissionSheet.obj = obj)"
    />
    <all-homework-submissions
      v-if="canManage"
      :homework-id="Number($route.params.homeworkId)"
      :visible="showSubmissions"
      @close="showSubmissions = false"
    />
    <div v-if="!isLoading">
      <!-- staff submission management section -->
      <v-footer
        v-if="canManage"
        color="white"
        app
        fixed
        class="justify-center py-2 ma-auto"
        elevation="10"
      >
        <!-- <v-toolbar v-if="canManage" bottom absolute min-width="100%" app> -->
        <p class="pa-0 ma-0">
          <strong v-if="homework"
            >{{ homework.submission_count }} Submissions</strong
          >
        </p>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="showSubmissions = true">View All</v-btn>
      </v-footer>
      <!-- student submission section -->
      <v-footer
        v-if="currentUser.is_student"
        color="white"
        app
        fixed
        class="justify-center py-2 ma-auto"
        elevation="10"
      >
        <!-- <v-toolbar v-if="currentUser.is_student" bottom absolute min-width="100%"> -->
        <p class="pa-0 ma-0">
          <strong v-if="submission" class="green--text"
            >Homework Submitted!</strong
          >
          <strong v-else class="red--text">Homework Not Submitted</strong>
        </p>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          v-if="submission"
          @click="
            $router.push({
              name: 'HomeworkSubmission',
              params: { submissionId: submission.id },
            })
          "
          >View</v-btn
        >
        <v-btn
          color="primary"
          v-else-if="!isLoading && homework"
          @click="submissionSheet.visible = true"
          >Submit</v-btn
        >
      </v-footer>
    </div>
  </div>
</template>
<script>
import TextHelper from '@utils/text_helper';
import Mixins from "@utils/mixins";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import EventBus from "@utils/event_bus";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import AllHomeworkSubmissions from "@components/homework/AllHomeworkSubmissions";
export default {
  name: "HomeworkDetails",
  mixins: [
    Mixins.essentials,
    Mixins.pullToRefresh,
    Mixins.handleAppBarTitle,
    Mixins.downloadEssentials,
  ],
  components: {
    ConfirmationDialog,
    IntractCreateEditSheet,
    AllHomeworkSubmissions,
  },
  data() {
    return {
      TextHelper,
      moment,
      featureName: "Books",
      appBarTitle: this.homeworkTitle,
      initialImage: null,
      pr: null,
      showSubmissions: false,
      editHomeworkSheet: {
        visible: false,
        editId: null,
        obj: {
          assignee: null,
        },
      },
      copyToOtherClassroomDialog: {
        visible: false,
        classroom: null,
        classroomSubject: null,
      },
      roomSubjects: [],
      submissionSheet: {
        visible: false,
        createSuccessMessage: "Homework submitted successfully!",
        editId: null,
        obj: {
          student: null,
          homework: this.$route.params.homeworkId,
        },
      },
      showPublishDialog: false,
      homework: null,
      showDeleteDialog: false,
      showVideo: false,
      videoLink: null,
      submission: null, // student: used to check if homework was submitted
    };
  },
  computed: {
    ...mapGetters([
      "allRooms",
    ]),
    documentsList() {
      return this.homework.homework_documents;
    },

    canManage() {
      // the homework should be loaded and currentuser should either be an admin or the faculty which created the homework
      return (
        this.homework &&
        (this.currentUser.is_admin ||
          (this.currentUser.is_faculty &&
            this.userSubjects.find(
              (subject) => subject.id == this.homework.subject.id
            ) != null))
      );
    },
    subjectsEndpoint() {
      if (!this.homework) return null;
      if (this.currentUser.is_student || this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          this.endpoints.subjectViewSet,
          this.homework.subject.room ? "room=" + this.homework.subject.room.id : "eligible_rooms__in=" + this.homework.subject.eligible_rooms[0].id
        );
      // if faculty
      return this.Helper.addUrlParams(
        this.endpoints.subjectViewSet,
        "faculty=" + this.currentUser.id
      );
    },
    formFields() {
      return {
        subject: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Subject",
          md: 6,
          required: true,
          endpoint: this.subjectsEndpoint,
          itemText: "name",
          itemValue: "id",
          getSubtitle:  (item) => item.room ? item.room.sections : "Extra Subject",
          // itemSubtitle: "room.sections", needs rework TODO
        },
        deadline: {
          fieldType: CreateEditFieldTypes.DATEPICKER,
          textModel: null,
          min: new Date(),
          label: "Deadline",
          helper: "When would be the last date of submission?",
          required: true,
          md: 6,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        homework_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          camera: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },

    submissionFormFields() {
      return {
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        homework_submission_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          camera: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },
  },
  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),

    async copyHomework(){

        var url = this.endpoints.homeworkViewSet;
        var postBody = {
          ...this.homework,
          id: null,
          subject: this.copyToOtherClassroomDialog.classroomSubject,
          assignee: this.currentUser.id,
        }

        // postBody.subject = this.copyToOtherClassroomDialog.classroomSubject; 
        // console.log(postBody);
        var response = await this.api.call(this.essentials, url, this.api.Methods.POST, postBody);
        
        if(response){
          this.showSnackbar({
            title: "Homework copied successfully",
            type: "success",
          });
          
          // var self = this;
          // setTimeout(() => {
          //   self.$router.replace({
          //     name: "HomeworkDetails",
          //     params: { homeworkId: response.id },
          //   });
          // }, 1000);
        }

        this.copyToOtherClassroomDialog.visible = false; 
        this.copyToOtherClassroomDialog.classroom = null; 
        this.copyToOtherClassroomDialog.classroomSubject = null;
    },
    async homeworkEdited() {
      this.onRefresh();
      this.editHomeworkSheet.editId = null;
    },

    finalOperationsBeforeHomeworkEdited(obj) {
      if (obj.deadline) obj.deadline = moment(obj.deadline).format();
      return obj;
    },

    async getHomeworkDetails() {
      var url =
        this.endpoints.homeworkViewSet + this.$route.params.homeworkId + "/";
      this.homework = await this.api.call(this.essentials, url);
      EventBus.$emit("homework__homework_updated", this.homework);
      this.appBarTitle = this.homework.title;
      // this.editHomeworkSheet.obj.book_topic = this.homework.book_topic;
      this.checkIfDocumentsDownloaded();
      if (this.currentUser.is_student) this.checkIfHomeworkSubmitted();
    },

    async deleteHomework() {
      this.showDeleteDialog = false;
      var url =
        this.endpoints.homeworkViewSet + this.$route.params.homeworkId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Topic Homework successfully deleted",
          type: "success",
        });
        EventBus.$emit(
          "homework__homework_deleted",
          this.$route.params.homeworkId
        );
        this.$router.back();
      }
    },

    async checkIfHomeworkSubmitted() {
      var url = this.Helper.addUrlParams(
        this.endpoints.homeworkSubmissionViewSet,
        ["homework=" + this.homework.id, "student=" + this.currentUser.id]
      );
      var response = await this.api.call(this.essentials, url);
      if (response.count != 0) this.submission = response.results[0];
    },

    async setAppBar() {
      if (!this.canManage) return;
      this.setAppBarOptions({
        title: this.appBarTitle,
        actions: [
          {
            id: 1,
            title: "Edit",
            icon: "mdi-pencil",
            onClick: () => {
              this.editHomeworkSheet.visible = true;
              this.editHomeworkSheet.editId = Number(
                this.$route.params.homeworkId
              );
            },
          },
          {
            id: 2,
            title: "Copy to Other Classroom",
            icon: "mdi-content-copy",
            onClick: () => (this.copyToOtherClassroomDialog.visible = true),
          },
          {
            id: 3,
            title: "Delete",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      });
    },

    onRefresh() {
      this.getHomeworkDetails();
    },
  },

  beforeDestroy() {
    this.resetAppBarOptions();
  },

  watch: {
    'copyToOtherClassroomDialog.classroom': async function(){
        // console.log(this.createEditActivitySheet.obj.rooms) 
        if(this.copyToOtherClassroomDialog.classroom){
          var url = this.Helper.addUrlParams(this.endpoints.subjectViewSet, [`room=${ this.copyToOtherClassroomDialog.classroom }`])
          // console.log(url);
          this.roomSubjects = await this.api.call(this.essentials, url)
          // console.log(this.roomSubjects);
       }
      }
  }, 
  async created() {
    await this.getHomeworkDetails();
    if (this.canManage) this.setAppBar();
    if (this.currentUser.is_student)
      this.submissionSheet.obj.student = this.currentUser.id;
    else this.editHomeworkSheet.obj.assignee = this.currentUser.id;
  },
};
</script>